body {
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: 14px;
    color: #333;
    margin: 0;
    padding: 0;
  }
  
  .tooltip {
    position: absolute;
    color: #fff;
    padding: 10px;
    width: 100px;
    height: 100px;
    background: gray;
    transform: translate3d(-50%, -50%, 0);
    border-radius: 5px;
    pointer-events: none;
    z-index: 1000;
    text-align: center;
  }
  
  a {
    color: #08c;
    text-decoration: none;
  }
  
  a:hover {
    text-decoration: underline;
  }
  
  /* .container {
    margin-left: auto;
    margin-right: auto;
    padding: 1em;
  } */
  
  .grid {
    margin-top: 50p;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  
  .presenter {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 9999;
  }
  
  .source {
    margin-right: 50px;
    margin-left: 50px;
    flex-grow: 9999;
  }
  
  .footer {
    margin-top: 50px;
    border-top: 1px solid #eee;
    padding: 20px 0;
    font-size: 12px;
    color: #999;
  }
  
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #222;
    font-weight: 100;
    margin: 0.5em 0;
  }
  
  pre.message {
    white-space: normal;
    width: 600px;
    text-align: center;
  }
  
  label {
    color: #999;
    display: inline-block;
    font-size: 0.85em;
    font-weight: bold;
    margin: 1em 0;
    text-transform: uppercase;
  }
  
  .hint {
    margin: 15px 0;
    font-style: italic;
    color: #999;
  }
  